import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Wrapper from '../components/wrapper';


export default () => {
  return (
    <Wrapper page=''>
      <div className='row'>
        <div className='col-md-3 col-sm-12'>
          <img alt='Wire Specialties' src={withPrefix('/img/logo.svg')} className='img-fluid logo' />
        </div>
        <div className='col-md-6 offset-md-3 col-sm-9 '>
          <blockquote className='blockquote mt-5 mt-md-0'>
            <p>
              Welcome to Wire Specialties! We are the North American
              distributors for Furukawa Electric. We supply triple-insulated
              winding wires for power supplies and transformers. Our office is
              located in Amherst, New Hampshire and our wires are shipped from
              our warehouse in Atlanta, Georgia.
            </p>
            <p>
              TEX-E winding wire is ideal for transformer and power supply
              applications that require a compact design. Also, no margin or
              interlayer tapes are required when utilizing TEX-E winding wire.
              Many application designs have achieved 50% volume and 65% weight
              reductions.
            </p>
          </blockquote>
        </div>
      </div>
    </Wrapper>
  );
};

export const pageQuery = graphql`
  query PageQuery {
    sitePage {
      path
    }
  }
`;
